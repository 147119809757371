/* fira-sans-regular - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('/layout/fonts/fira-sans-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-italic - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 400;
    src: local(''),
    url('/layout/fonts/fira-sans-v15-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v15-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-500 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('/layout/fonts/fira-sans-v15-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v15-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}